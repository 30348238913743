import React from 'react';
import styled from 'styled-components';
import SocialButton from './socialbutton';


const Message = styled.p`
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 3em;
    text-align: center;

    color: #271E2C;
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-flow: column;

    margin: 0;
    padding: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    background: #F2F2F2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
`

const Logo = styled.img`
    width: 256px;
    height: 256px; 
`

const SocialWrapper = styled.ul`
    min-width: 240px;
    list-style-type:none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
`

class Moving extends React.Component {
    render() {
        return (
            <Wrapper>
                <Logo src="logo512.png" alt="Bittner Systems Logo" id="MovingLogo"/>
                <Message> Ich baue um...</Message>
                <SocialWrapper>
                    <SocialButton branding url="https://discordapp.com/invite/0ba3BcJZBHF82oqB" medium="discord"></SocialButton>
                    <SocialButton branding url="https://steamcommunity.com/id/h3ul1/" medium="steam"></SocialButton>
                    <SocialButton branding url="https://www.amazon.de/gp/profile/amzn1.account.AFAKIC7OW65ESQWD2BOIB3FEQCTA?ie=UTF8" medium="amazon"></SocialButton>
                    <SocialButton url="https://docs.google.com/forms/d/e/1FAIpQLSdpWE2AQ5qP7kzrJnIOwyOjFzjD7tqXLJBvoSB6ho19j8DGGg/viewform" medium="toolbox"></SocialButton>
                    <SocialButton url="mailto:timbittner21@gmail.com" medium="envelope"></SocialButton>
                </SocialWrapper>
            </Wrapper>
        )
    }
}

export default Moving;