import React from 'react';
import styled from 'styled-components';
import '../fonts/fontawesome'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const SocialLink = styled.a`
    font-size: 64px;
    line-height: 74px;
    color: #23272A;
    padding: 8px;

    &:hover {
        color: #6c339a;
    }
`

const ListItem = styled.li`
    display: inline-block;
`

class SocialButton extends React.Component {
    render() {
        return (
            <ListItem>
                <SocialLink href={this.props.url} className="SocialMediaButton">
                    <FontAwesomeIcon icon={this.props.branding ? ['fab', this.props.medium] : this.props.medium} />
                </SocialLink>
            </ListItem>
        )
    }
}

export default SocialButton;