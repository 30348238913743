import React from 'react';
import './App.css';
import Moving from './components/moving.js';

function App() {
  return (
    <Moving/>
  );
}

export default App;
