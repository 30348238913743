// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import { faEnvelope, faToolbox } from '@fortawesome/free-solid-svg-icons'
// brand icons
import { faDiscord, faSteam, faAmazon } from '@fortawesome/free-brands-svg-icons'

library.add(
    faDiscord,
    faAmazon,
    faSteam,

    faToolbox,
    faEnvelope
  // more icons go here
);